import backend_url from "./constants";

// Updated getDefaultFailureCaseGraph function to fetch the failure case circle graph from the backend API
const getDefaultFailureCaseGraph = async () => {
  try {
    const url = backend_url.concat("get_failure_case_circle_graph");

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    // Assuming the response is in JSON format
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Could not fetch the default circle graph:", error);
    // Optional: Return an error message or handle it differently if the API call fails
    // You might want to return a default value or re-throw the error
    throw error;
  }
};

export default getDefaultFailureCaseGraph;

// used in src/components/dashboard_components/DatasetAnalysisDemoTab.js

// same as getDefaultCirlceGraph.js
