import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import ProjectAction from "./ProjectAction"; // Make sure to use the correct path
import ProjectMenu from "./ProjectMenu"; // Make sure to adjust the path as necessary

const ProjectList = ({ projects, onProjectClick, refreshProjects }) => {
  // Define the columns for the DataGrid

  const columns = [
    {
      field: "options",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => (
        <ProjectMenu
          projectId={params.row.id}
          refreshProjects={refreshProjects}
        />
      ),
    },
    { field: "name", headerName: "Project Name", width: 100, flex: 1 },
    { field: "id", headerName: "Project ID", width: 100, flex: 0.5 },
    {
      field: "skills",
      headerName: "Number of Skills",
      width: 75,
      flex: 1,
    },
    {
      field: "datasetSize",
      headerName: "Dataset Size",
      width: 100,
      flex: 1,
    },
    {
      field: "pendingDataSize",
      headerName: "Pending Data to Tag",
      width: 100,
      flex: 1,
    },
    { field: "datasetPath", headerName: "Dataset Path", width: 200, flex: 1 },
    {
      field: "action",
      headerName: "",
      align: "right",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <ProjectAction
          projectRow={params.row}
          refreshProjects={refreshProjects}
          onProjectClick={onProjectClick}
        />
      ),
    },
  ];

  // Prepare the rows for the DataGrid
  const rows = projects.map((project) => ({
    id: project._id.$oid,
    name: project.name,
    skills: project.skills ? project.skills.length : null,
    pendingDataSize: project.number_of_pending_datums_to_tag,
    datasetSize: project.number_of_datums,
    datasetPath: project.s3_bucket_details
      ? project.s3_bucket_details.dataset_path
      : null,
  }));

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </div>
  );
};
export default ProjectList;
