// src/api/API.js
import { fetchAuthSession } from "aws-amplify/auth";

// dev-stack: "https://21v6hdcfx3.execute-api.us-west-1.amazonaws.com/Prod"

const BASE_URL = "https://s0tyhb09l1.execute-api.us-west-1.amazonaws.com/Prod";

const getAuthHeader = async () => {
  try {
    const { _, idToken } = (await fetchAuthSession()).tokens;
    return {
      Authorization: idToken,
    };
  } catch (error) {
    console.error("Error getting authentication token:", error);
    throw new Error("User not authenticated");
  }
};

const fetchData = async (endpoint, method = "GET", payload = null) => {
  const headers = await getAuthHeader();

  const config = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: headers.Authorization.toString(),
    },
  };

  if (payload && (method === "POST" || method === "PUT")) {
    config.body = JSON.stringify(payload);
  }

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, config);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Error fetching data");
    }

    return data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

const API = {
  get: (endpoint) => fetchData(endpoint, "GET"),
  post: (endpoint, payload) => fetchData(endpoint, "POST", payload),
  put: (endpoint, payload) => fetchData(endpoint, "PUT", payload),
  delete: (endpoint) => fetchData(endpoint, "DELETE"),
  // Add more methods if needed
};

export default API;
