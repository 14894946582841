import React from "react";
import Box from "@mui/material/Box";
import { styled, alpha } from "@mui/material/styles";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LabelIcon from "@mui/icons-material/Label";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks/useTreeViewApiRef";

const transformDataToTreeViewItems = (data) => {
  return data.map((item, index) => {
    return {
      id: item.name,
      label: item.name + " (" + item.value + ")",
      volume: item.value,
      children: item.children
        ? transformDataToTreeViewItems(item.children)
        : [],
    };
  });
};

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    // padding: theme.spacing(0.5, 1),
    // margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: "0.8rem",
      fontWeight: 500,
      color:
        theme.palette.mode === "dark" && theme.palette.primary.contrastText,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    // paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const TreeView = ({
  data,
  selectedFilters,
  setSelectedTag,
  selectedTag,
  setHoveredTag,
  tagColorMap,
  hoveredDatapoint,
}) => {
  const treeRef = useTreeViewApiRef();

  // Transform your hierarchical data to the required format
  //check that data != {}
  if (Object.keys(data).length === 0) {
    return <div></div>;
  }
  const treeViewItems = transformDataToTreeViewItems([data]);

  const findItem = (items, itemId) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === itemId) {
        return items[i];
      }
      if (items[i].children) {
        const result = findItem(items[i].children, itemId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    // Traverse the treeViewItems and return the item with the given itemId
    const selectedItem = findItem(treeViewItems, itemId);
    const treeSelectedTag = {
      name: selectedItem.id,
      volume: selectedItem.volume,
      children: selectedItem.children,
    };
    if (
      selectedTag &&
      treeSelectedTag &&
      treeSelectedTag.name === selectedTag.name
    ) {
      setSelectedTag(null);
    } else {
      setSelectedTag(treeSelectedTag);
    }
  };

  const handleItemFocus = (event, itemId) => {
    // TODO: Figure this out
    console.log("hovered: ", itemId);
  };

  return (
    <Box
      sx={{
        height: "100%",
        flexGrow: 1,
        maxWidth: "100%",
        overflow: "auto",
        paddingBottom: "50px",
      }}
    >
      <RichTreeView
        ref={treeRef}
        slots={{
          expandIcon: ChevronRightIcon,
          collapseIcon: ExpandMoreIcon,
          endIcon: LabelIcon,
          item: StyledTreeItem,
        }}
        items={treeViewItems}
        onItemSelectionToggle={handleItemSelectionToggle}
        onItemFocus={handleItemFocus}
      />
    </Box>
  );
};

export default TreeView;
