import API from "../../api/API";

const getDatapoint = async ({ id, projectId }) => {
  try {
    const data = await API.post(
      `/legacy_demo/get_datapoint?project-id=${projectId}`,
      {
        datum_id: id,
      }
    );

    const datum = data["legacy_datum"];

    return datum;
  } catch (error) {
    console.error("Could not get datapoint:", error);
    throw error;
  }
};

export default getDatapoint;
