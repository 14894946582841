import React, { useState } from "react";
import { TextField, Typography, Box } from "@mui/material";

const Step2_IdentifySkills = ({ projectDetails, setProjectDetails }) => {
  // A local state to handle JSON parse errors
  const [error, setError] = useState("");

  const handleInputChange = (event) => {
    // Use the name to identify which part of project details to update
    let { name, value } = event.target;
    if (name === "skills") {
      try {
        // Attempt to parse JSON only if it's not empty
        if (value.trim()) {
          const skillsObject = JSON.parse(value);
          // Set parsed skills object in the state
          setProjectDetails({ ...projectDetails, skills: skillsObject });
        } else {
          // If the field is cleared, set skills back to an empty string or an empty array based on your API expectation
          setProjectDetails({ ...projectDetails, skills: [] });
        }
        // Reset error if the parsing is successful
        setError("");
      } catch (e) {
        console.log("JSON PARSE ERROR", e);
        // Catch and set any JSON parse errors
        setError("Invalid JSON format");
      }
    } else {
      // For all other fields, set their values as they were
      setProjectDetails({ ...projectDetails, [name]: value });
    }
  };

  return (
    <Box
      className="step2-identify-skills"
      maxWidth="sm"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Typography variant="h6" gutterBottom>
        Identify Skills
      </Typography>
      <TextField
        variant="outlined"
        name="skills"
        label="Skills"
        placeholder="Paste a JSON formatted description of your skills."
        onChange={handleInputChange}
        multiline
        rows={10}
        fullWidth
        error={!!error}
        helperText={error}
      />
    </Box>
  );
};

export default Step2_IdentifySkills;
