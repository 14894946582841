import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectManagerPage from "./pages/ProjectManagerPage";
import ProjectSetupPage from "./pages/ProjectSetupPage";
import DashboardPage from "./pages/DashboardPage";
// import theme from "./styles/Theme";
import { withAuthenticator, Button, Heading } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import getTheme from "./styles/getTheme";

function App({ signOut, user }) {
  const theme = createTheme(getTheme("light"));

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/projects"
              element={
                <ProjectManagerPage
                  theme={theme}
                  signOut={signOut}
                  user={user}
                />
              }
            />
            <Route
              path="/projects/new"
              element={<ProjectSetupPage signOut={signOut} user={user} />}
            />
            <Route
              path="/dashboard/:projectId"
              element={
                <DashboardPage theme={theme} signOut={signOut} user={user} />
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default withAuthenticator(App);
