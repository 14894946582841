import API from "./API";

export const initializeSkills = async (projectId, skillTree) => {
  try {
    // Since this is a POST request, we provide the project-id as a query parameter
    // and the skill tree in the request body.

    const data = await API.post(`/skill/initialize?project-id=${projectId}`, {
      skill_tree: skillTree,
    });
    return data; // Assuming the response data structure matches that of the lambda
  } catch (error) {
    console.error("Error initializing skills:", error);
    throw error;
  }
};
