import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import getTagJobs from "../../functions/tag_jobs_functions/getTagJobs";
import Button from "@mui/material/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import retryTagJobs from "../../functions/tag_jobs_functions/retryTagJobs";
import CircularProgress from "@mui/material/CircularProgress";

export default function TagJobsTab({ projectId, theme, failureMode = false }) {
  const [tagJobs, setTagJobs] = useState([]);
  const [failedJobs, setFailedJobs] = useState([]);
  const [isJobsVisible, setIsJobsVisible] = useState(true);
  const [isFailedJobsVisible, setIsFailedJobsVisible] = useState(true);
  const [polling, setPolling] = useState(false); // New state for polling
  const [retryLoading, setRetryLoading] = useState(false); // New state for managing retry loading

  const intervalTime = 5000; // Adjust the interval as needed

  const fetchTagJobs = async () => {
    const tag_jobs = await getTagJobs(projectId);
    const processedData = tag_jobs.map((job) => ({
      id: job._id.$oid,
      createdAt: new Date(job.created_at.$date).toLocaleString(),
      status: mapStatusCodeToStatus(job.status.statusCode), // Map status code to status
      type: job.type,
    }));

    // Separate failed jobs
    const failedJobsData = processedData.filter(
      (job) => job.status === "Failed"
    );
    const otherJobsData = processedData.filter(
      (job) => job.status !== "Failed"
    );

    setFailedJobs(failedJobsData);
    setTagJobs([...failedJobsData, ...otherJobsData]); // Failed jobs are shown first

    const shouldPoll = processedData.some(
      (job) => job.status === "Queued" || job.status === "In Progress"
    );

    if (shouldPoll && !polling) {
      setPolling(true);
    } else if (!shouldPoll && polling) {
      setPolling(false);
    }
  };

  useEffect(() => {
    fetchTagJobs();

    let intervalId;
    if (polling) {
      // Start polling
      intervalId = setInterval(() => {
        fetchTagJobs();
      }, intervalTime); // Adjust the interval as needed
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [polling]);

  const handleRetryFailedJobs = async () => {
    setRetryLoading(true); // Set loading state
    try {
      await retryTagJobs(projectId); // Assume retryTagJobs accepts projectId and retries jobs
      // On success:
      setPolling(true); // Reset polling to true if needed
      setRetryLoading(false); // Reset loading state
    } catch (error) {
      // Handle error
      setRetryLoading(false); // Ensure loading state is reset even if there's an error
      console.error("Retry failed jobs error:", error);
    }
  };

  // Function to map status codes to user-friendly status messages
  const mapStatusCodeToStatus = (statusCode) => {
    switch (statusCode) {
      case 404:
        return "Failed";
      case 200:
        return "Success";
      case 202:
        return "Queued";
      case 102:
        return "In Progress";
      default:
        return "Unknown";
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        let color = "default";
        switch (params.value) {
          case "Failed":
            color = "error";
            break;
          case "Success":
            color = "primary";
            break;
          case "Queued":
            // Choose an appropriate color from your theme
            color = "textSecondary";
            break;
          case "In Progress":
            // Choose an appropriate color from your theme
            color = "info";
            break;
          default:
            break;
        }

        return <Typography color={color}>{params.value}</Typography>;
      },
    },
  ];

  const handleToggleJobs = () => {
    setIsJobsVisible(!isJobsVisible);
  };

  const handleToggleFailedJobs = () => {
    setIsFailedJobsVisible(!isFailedJobsVisible);
  };

  return (
    <Container>
      <Box pt={theme.spacing(5)} sx={{ height: "100%", width: "100%" }}>
        <Typography variant="h4">Project Jobs</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Monitor and manage your project's data processing tasks.
        </Typography>

        {failedJobs && failedJobs.length > 0 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={theme.spacing(3)}
            >
              {" "}
              <Box display="flex" alignItems="center" marginBottom={2}>
                <IconButton onClick={handleToggleFailedJobs} size="large">
                  <ExpandMoreIcon
                    sx={{
                      transform: isFailedJobsVisible
                        ? "rotate(0deg)"
                        : "rotate(-90deg)",
                      transition: "transform 0.25s",
                    }}
                  />
                </IconButton>
                <Typography variant="h5">Failed Jobs</Typography>
              </Box>
              {retryLoading ? (
                <CircularProgress color="error" />
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<ErrorOutlineIcon />}
                  onClick={handleRetryFailedJobs}
                >
                  Retry Failed Jobs
                </Button>
              )}
            </Box>

            <Collapse in={isFailedJobsVisible}>
              <DataGrid
                rows={failedJobs}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                sx={{ mb: 2 }}
              />
            </Collapse>
          </>
        )}
        <Box display="flex" alignItems="center" marginBottom={2}>
          <IconButton onClick={handleToggleJobs} size="large">
            <ExpandMoreIcon
              sx={{
                transform: isJobsVisible ? "rotate(0deg)" : "rotate(-90deg)",
                transition: "transform 0.25s",
              }}
            />
          </IconButton>
          <Typography variant="h5">All Jobs</Typography>
        </Box>

        <Collapse in={isJobsVisible}>
          <DataGrid
            rows={tagJobs}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </Collapse>
      </Box>
    </Container>
  );
}
