import React, { useState } from "react";
import Box from "@mui/material/Box";
import { styled, alpha } from "@mui/material/styles";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LabelIcon from "@mui/icons-material/Label";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks/useTreeViewApiRef";

function pruneAndEnrichCircleGraph(circleGraph, tagsList, leafTagValueDict) {
  function dfs(currentNode) {
    // Check if the current node's name is in the tags list
    if (!tagsList.includes(currentNode.name) && currentNode.name !== "Root") {
      return null; // Prune the node by not including it in the output
    }

    // If the node is in the tags list, prepare to keep it
    const newNode = {
      name: currentNode.name,
      // Check if there's additional information in leafTagValueDict
      ...(leafTagValueDict[currentNode.name] && {
        description: leafTagValueDict[currentNode.name].description,
      }),
    };

    // Recursively process child nodes
    if (currentNode.children && currentNode.children.length > 0) {
      newNode.children = currentNode.children
        .map(dfs)
        .filter((child) => child !== null);
    }

    return newNode; // Include this node in the output
  }

  return dfs(circleGraph); // Start DFS from the root of the circle graph
}

const transformDataToTreeViewItems = (data) => {
  return data.map((item, index) => {
    return {
      id: item.name,
      label: item.description
        ? item.name + ": " + item.description.replace(/["':]/g, "")
        : item.name,
      children: item.children
        ? transformDataToTreeViewItems(item.children)
        : [],
    };
  });
};

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    // padding: theme.spacing(0.5, 1),
    // margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: "0.8rem",
      fontWeight: 500,
      color:
        theme.palette.mode === "dark" && theme.palette.primary.contrastText,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    // paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const DatumTagTree = ({ circleGraph, tagsList, leafTagValueDict }) => {
  const datumTagTreeGraph = pruneAndEnrichCircleGraph(
    circleGraph,
    tagsList,
    leafTagValueDict
  );
  const treeViewItems = transformDataToTreeViewItems([datumTagTreeGraph]);

  // TODO: auto expand
  // https://mui.com/x/react-tree-view/rich-tree-view/expansion/

  return (
    <Box
      sx={{
        height: "100%",
        flexGrow: 1,
        maxWidth: "100%",
        overflow: "auto",
        paddingBottom: "30px",
      }}
    >
      <RichTreeView
        slots={{
          expandIcon: ChevronRightIcon,
          collapseIcon: ExpandMoreIcon,
          endIcon: LabelIcon,
          item: StyledTreeItem,
        }}
        items={treeViewItems}
      />
    </Box>
  );
};

export default DatumTagTree;
