import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DatasetAnalysisTab from "../components/dashboard_components/DatasetAnalysisTab";
import DashboardDrawer from "../components/dashboard_components/DashboardDrawer";
import RightChevronIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import TagJobsTab from "../components/dashboard_components/TagJobsTab";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Chip from "@mui/material/Chip";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function DashboardPage({
  theme,
  currentDataset = "HUMAN-EVAL",
  datasets = ["HUMAN-EVAL", "MNIST"],
}) {
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("dataset_analysis");

  //Get slug from url
  const projectId = window.location.pathname.split("/").pop();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // switch component between dataset_analysis and tag_jobs
  const SwitchTab = () => {
    switch (currentTab) {
      case "dataset_analysis":
        return <DatasetAnalysisTab projectId={projectId} theme={theme} />;
      case "tag_jobs":
        return <TagJobsTab projectId={projectId} theme={theme} />;
      default:
        return <DatasetAnalysisTab theme={theme} />;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "white" }}
        elevation={1}
      >
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            sx={{
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 3,
                padding: 0,
                paddingTop: 0.5,
                paddingLeft: 0.5,
                ...(open && { display: "none" }),
              }}
            >
              {/* Pull icon from public/icon.png */}
              <img src="/logo.png" alt="icon" width="30" height="30" />
            </IconButton>
            {/* <Typography variant="h6" color="black" component="div">
              {currentTab === "dataset_analysis" ? "Acadia" : "Jobs"}
            </Typography> */}
            <Typography
              variant="h6"
              color="black"
              sx={{ fontWeight: "bold", opacity: ".8" }}
            >
              Acadia
            </Typography>
            <Chip
              label="Playground"
              sx={{
                ml: 1,
                borderRadius: "4px",
                height: "20px",
              }}
            />
          </Box>
          <Select
            value={currentDataset}
            onChange={() => {}}
            displayEmpty
            size="small"
            // disabled // The select is disabled as requested
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              ".MuiSelect-select": {
                paddingRight: "24px", // Adjust right padding to show dropdown arrow properly
                border: "none", // Remove border
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Ensure no border for outlined variant
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: "none", // Remove hover effect
              },
              "&:before": {
                borderBottom: "none", // Remove default underline
              },
              "&:after": {
                borderBottom: "none", // Remove focus underline
              },
            }}
          >
            {datasets.map((dataset) => (
              <MenuItem key={dataset} value={dataset}>
                {dataset}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="black"
            size="large"
          >
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DashboardDrawer
        open={open}
        handleDrawerClose={handleDrawerClose}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <Box component="main" height="100vh" sx={{ flexGrow: 1 }}>
        <Toolbar variant="dense" />
        <Box height="calc(100vh - 48px)">
          <SwitchTab />
        </Box>
      </Box>
    </Box>
  );
}
