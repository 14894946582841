import React from "react";
import { Typography, Box, Paper, TextField } from "@mui/material";

const Step4_ConnectS3Bucket = ({ projectDetails, onInputChange }) => {
  return (
    <Box
      className="step4-connect-s3-bucket"
      maxWidth="sm"
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h6" gutterBottom>
        Connect your Amazon S3 Bucket
      </Typography>
      <Typography variant="body2" gutterBottom>
        Provide the IAM Role ARN and Bucket Name
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        Once the stack creation process completes, navigate to the "Outputs" tab
        of your CloudFormation stack in the AWS Console. Here, you will find the
        ARN (Amazon Resource Name) of the IAM Role created by the CloudFormation
        stack.
      </Typography>
      <TextField
        label="Amazon S3 Bucket Name"
        name="s3BucketName"
        placeholder="Enter your S3 bucket name here..."
        value={projectDetails.s3BucketName}
        onChange={onInputChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        label="S3 Dataset Path"
        name="datasetPath"
        placeholder="Enter your dataset path here..."
        value={projectDetails.datasetPath}
        onChange={onInputChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        label="S3 Metadata Path Name"
        name="metadataPath"
        placeholder="Enter your metadata path here..."
        value={projectDetails.metadataPath}
        onChange={onInputChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        label="Dataset Description"
        name="datasetDescription"
        placeholder="Enter your dataset description here..."
        value={projectDetails.datasetDescription}
        onChange={onInputChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        label="Task Description"
        name="taskDescription"
        placeholder="Enter your task description here..."
        value={projectDetails.taskDescription}
        onChange={onInputChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        label="IAM Role ARN"
        name="iamRoleArn"
        placeholder="Enter your IAM Role ARN here..."
        value={projectDetails.iamRoleArn}
        onChange={onInputChange}
        variant="outlined"
        margin="normal"
      />
      {/* Add buttons or any additional components as needed */}
    </Box>
  );
};

export default Step4_ConnectS3Bucket;
