import React from "react";
import CirclePackingZoomable from "./CirclePackingZoomable";
import { Box } from "@mui/material";

const SkillGraphCard = ({
  projectId,
  theme,
  selectedFilters,
  setSelectedFilters,
  tagColorMap,
  circleGraph,
  hoveredTag,
  setHoveredTag,
  hoveredDatapoint,
  failureMode,
  selectedTag,
  setSelectedTag,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CirclePackingZoomable
        data={circleGraph}
        width={1000}
        height={1000}
        selectedFilters={selectedFilters}
        setSelectedTag={setSelectedTag}
        selectedTag={selectedTag}
        hoveredTag={hoveredTag}
        setHoveredTag={setHoveredTag}
        tagColorMap={tagColorMap}
        hoveredDatapoint={hoveredDatapoint}
        failureMode={failureMode}
      />

      {/* <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
        <TagInformation
          theme={theme}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          tagColorMap={tagColorMap}
        ></TagInformation>
      </Box> */}
    </Box>
  );
};

export default SkillGraphCard;
