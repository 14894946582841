//src/api/UserApi.js
import API from "./API";

export const getUserProjects = async () => {
  try {
    const data = await API.post("/user/projects", {});
    return data["projects"];
  } catch (error) {
    console.error("Error getting user projects:", error);
    throw error;
  }
};
