import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Step1_ProjectDetails from "../components/project_setup_components/Step1_ProjectDetails";
import Step2_IdentifySkills from "../components/project_setup_components/Step2_IdentifySkills";
import Step3_PrepareS3Bucket from "../components/project_setup_components/Step3_PrepareS3Bucket";
import Step4_ConnectS3Bucket from "../components/project_setup_components/Step4_ConnectS3Bucket";
import setupProjectSkillsDataset from "../functions/project_setup_functions/setupProjectSkillsDataset";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const ProjectSetupPage = ({ user }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [submitIsLoading, setSubmitIsLoading] = useState(false); // Loading state

  const [projectDetails, setProjectDetails] = useState({
    name: "",
    description: "",
    skills: "",
    s3BucketName: "",
    iamRoleArn: "",
    datasetPath: "",
    metadataPath: "",
    datasetDescription: "",
    taskDescription: "",
  });
  const navigate = useNavigate();
  const theme = useTheme();

  // Function to handle exiting the setup process
  const handleExitSetup = () => {
    navigate("/projects");
  };

  // Function to handle form data submission
  const handleSubmit = async () => {
    setSubmitIsLoading(true); // Start loading animation
    try {
      await setupProjectSkillsDataset(projectDetails);
      navigate("/projects"); // Redirect after setup is complete
    } catch (error) {
      console.error("Error setting up project:", error);
    } finally {
      setSubmitIsLoading(false); // Stop loading animation
    }
  };

  const handleNext = () => setCurrentStep(currentStep + 1);
  const handlePrev = () => setCurrentStep(currentStep - 1);
  const handleInputChange = (e) => {
    setProjectDetails({ ...projectDetails, [e.target.name]: e.target.value });
  };

  if (submitIsLoading) {
    return (
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {" "}
          Setting up your project...
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "2rem" }}>
      <Container maxWidth="lg" disableGutters={false}>
        <Box display="flex" pt={theme.spacing(6)}>
          <Box>
            <IconButton onClick={handleExitSetup}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box>
            <Typography
              variant="h6"
              gutterBottom
            >{`Create a project (Step ${currentStep} of 4)`}</Typography>
            {/* Step Components */}
            <Box pt={theme.spacing(2)}>
              {currentStep === 1 && (
                <Step1_ProjectDetails
                  projectDetails={projectDetails}
                  onInputChange={handleInputChange}
                  onNext={handleNext}
                />
              )}
              {currentStep === 2 && (
                <Step2_IdentifySkills
                  projectDetails={projectDetails}
                  setProjectDetails={setProjectDetails}
                  onNext={handleNext}
                  onPrev={handlePrev}
                />
              )}
              {currentStep === 3 && (
                <Step3_PrepareS3Bucket
                  projectDetails={projectDetails}
                  onNext={handleNext}
                  onPrev={handlePrev}
                />
              )}
              {currentStep === 4 && (
                <Step4_ConnectS3Bucket
                  projectDetails={projectDetails}
                  onInputChange={handleInputChange}
                  onSubmit={handleSubmit}
                  onPrev={handlePrev}
                />
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" mt={4}>
              {currentStep > 1 && (
                <Button variant="outlined" onClick={handlePrev}>
                  Previous
                </Button>
              )}
              {currentStep < 4 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Continue
                </Button>
              )}
              {currentStep === 4 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Create Project
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ProjectSetupPage;
