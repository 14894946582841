import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const names = ["Claude-3-Opus", "GPT-4"];

export default function ModelSelect({ selectedModels, setSelectedModels }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedModels(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ width: 200 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">Model</InputLabel>
        <Select
          size="small"
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedModels}
          onChange={handleChange}
          input={<OutlinedInput label="Model" />}
          renderValue={(selected) => selected.join(", ")}
          sx={{ borderRadius: 2 }}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedModels.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
