import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

const CirclePackingZoomable = ({
  data,
  width,
  height,
  selectedFilters,
  setSelectedTag,
  selectedTag,
  setHoveredTag,
  tagColorMap,
  hoveredDatapoint,
  failureMode,
}) => {
  const [circleSelectedTag, setCircleSelectedTag] = useState(null);
  const [circleTagNameClick, setCircleTagNameClick] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setTooltipPosition({ x: event.pageX, y: event.pageY });
    };

    // Add event listener to the window or specific SVG ref
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (circleTagNameClick === true) {
      if (
        selectedTag &&
        circleSelectedTag &&
        circleSelectedTag.name === selectedTag.name
      ) {
        setSelectedTag(null);
      } else {
        setSelectedTag(circleSelectedTag);
      }
      setCircleTagNameClick(false);
    }
  }, [circleSelectedTag, circleTagNameClick]);

  // Reference to the SVG element
  const ref = useRef();
  // Define a color scale for the circles
  const color = d3
    .scaleLinear()
    .domain([0, 5]) // Input domain for the depth of nodes
    .range(["#f0f0f0", "#303030"]) // Light grey to dark grey
    .interpolate(d3.interpolateHcl); // Interpolation method for colors

  const colorGreen = d3
    .scaleLinear()
    .domain([0, 5]) // Input domain for the depth of nodes
    .range(["#89ab85", "#556b52"]) // Light grey to dark grey
    .interpolate(d3.interpolateHcl); // Interpolation method for colors

  const colorRed = d3
    .scaleLinear()
    .domain([0, 5]) // Input domain for the depth of nodes
    .range(["#ff4242", "#8f2424"]) // Light red to dark red
    .interpolate(d3.interpolateHcl); // Interpolation method for colors

  useEffect(() => {
    if (!data) return; // Skip if data is not provided

    // Clear the SVG to prevent duplication on data update
    d3.select(ref.current).selectAll("*").remove();

    // Pack function to compute layout of circle packing
    const pack = (data) =>
      d3.pack().size([width, height]).padding(3)(
        d3
          .hierarchy(data) // Create a hierarchy from the data
          .sum((d) => d.value) // Define how to compute the size of each node
          .sort((a, b) => b.value - a.value) // Sort nodes by value
      );

    const root = pack(data); // Compute the layout for the given data

    // Create SVG element with a viewBox for responsive sizing and apply styles
    const svg = d3
      .select(ref.current)
      .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
      .attr(
        "style",
        `max-width: 100%; height: auto; display: block; cursor: pointer; background-color: white`
      );

    const getNodeColor = (d) => {
      if (selectedFilters.includes(d.data.name)) {
        const color = tagColorMap[d.data.name];
        return color;
      } else if (
        hoveredDatapoint &&
        hoveredDatapoint.tags_list.includes(d.data.name)
      ) {
        if (failureMode) {
          return colorRed(d.depth);
        } else return colorGreen(d.depth);
      } else if (d.children) {
        return color(d.depth);
      } else {
        return color(d.depth);
        // return "#d9d9d9";
      }
    };

    // Append circles for each node in the hierarchy except the root
    const node = svg
      .append("g")
      .selectAll("circle")
      .data(root.descendants().slice(1))
      .join("circle")
      .attr("fill", (d) => getNodeColor(d)) // Use color scale based on node depth
      // .attr("pointer-events", (d) => (!d.children ? "none" : null)) // Disable pointer events for leaf nodes
      .on(
        "click",
        (event, d) => focus !== d && (zoom(event, d), event.stopPropagation()) // Zoom on click
      );

    node
      .style("stroke", "none") // Default, no stroke
      .style("stroke-width", "0")
      .on("mouseover", function (event, d) {
        d3.select(this).style("stroke", "#555").style("stroke-width", "2");
        setTooltipContent({ name: d.data.name, volume: d.data.value });
        setHoveredTag(d.data.name);
      })
      .on("mouseout", function (event, d) {
        d3.select(this).style("stroke", "none").style("stroke-width", "0");
        setHoveredTag(null);
        setTooltipContent(null);
      });

    // Append text labels for each node
    const label = svg
      .append("g")
      .attr("pointer-events", "none") // Disable pointer events for labels
      .attr("text-anchor", "middle") // Center text on nodes
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .style("fill-opacity", (d) => (d.parent === root ? 1 : 0)) // Only show labels for root's immediate children
      .style("display", (d) => (d.parent === root ? "inline" : "none")) // Hide labels not for immediate children of root
      .text((d) => (d.data.value ? d.data.name : ""))
      .style("pointer-events", "all")
      // .attr("dy", "0.35em")
      // .attr("y", (d) => -(d.r * 0.8)) // Position text at the top of the circle
      .style("font-size", "22px")
      .on("click", (event, d) => {
        event.stopPropagation(); // Prevent the click from triggering zoom
        setCircleSelectedTag({
          name: d.data.name,
          volume: d.data.value,
          children: d.data.children,
        });
        // svg hooks and states are funky, so we need a manual flag to trigger a click
        // at the higher level parent function
        // basically a normal hook won't work here so we need this flag
        setCircleTagNameClick(true);
      });

    label
      .style("font-weight", "normal")
      .style("cursor", "pointer") // Change cursor to indicate clickable
      .on("mouseover", function (event, d) {
        d3.select(this).style("font-weight", "bold").style("font-size", "24px");
        setHoveredTag(d.data.name);
        setTooltipContent({ name: d.data.name, volume: d.data.value });
      })
      .on("mouseout", function (event, d) {
        d3.select(this)
          .style("font-weight", "normal")
          .style("font-size", "22px"); // Remove text outline
        setHoveredTag(null);
        setTooltipContent(null);
      });

    // Initial focus is set to the root of the hierarchy
    let focus = root;
    let view;

    // Apply click event to zoom out
    svg.on("click", (event) => zoom(event, root));

    // Initial zoom to focus on the root node
    zoomTo([root.x, root.y, root.r * 2]);

    // Function to smoothly transition to a new zoom view
    function zoomTo(v) {
      const k = width / v[2]; // Calculate zoom scale

      view = v;

      // Update transform for labels and nodes based on new view
      label.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr("r", (d) => d.r * k); // Adjust radius based on zoom scale
    }

    // Zoom function to update focus and initiate zoom transition
    function zoom(event, d) {
      if (!d.children) return;
      setCircleSelectedTag(null);
      if (event.target.tagName === "circle") {
        if (d.data.name !== "Root")
          setCircleSelectedTag({ name: d.data.name, volume: d.data.value });
        setCircleTagNameClick(true);
        focus = d;
        focus = d;

        const transition = svg
          .transition()
          .duration(event.altKey ? 7500 : 750) // Slow transition if alt key is pressed
          .tween("zoom", (d) => {
            const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]); // Interpolate between current and new zoom view
            return (t) => zoomTo(i(t)); // Apply interpolated zoom
          });

        // Update label visibility based on new focus
        label
          .filter(function (d) {
            return d.parent === focus || this.style.display === "inline";
          })
          .transition(transition)
          .style("fill-opacity", (d) => (d.parent === focus ? 1 : 0))
          .on("start", function (d) {
            if (d.parent === focus) this.style.display = "inline";
          })
          .on("end", function (d) {
            if (d.parent !== focus) this.style.display = "none";
          });
      }
    }
  }, [data, selectedFilters, hoveredDatapoint, failureMode]); // Re-run effect if data changes

  return (
    <>
      <svg ref={ref} width="100%" height="100%"></svg>
      {tooltipContent && (
        <div
          style={{
            position: "fixed",
            left: tooltipPosition.x + "px",
            top: tooltipPosition.y + "px",
            backgroundColor: tagColorMap[tooltipContent.name],
            border: "1px solid black",
            color: "black",
            padding: "5px",
            borderRadius: "5px",
            transform: "translate(20px, 20px)", // Offset from cursor
            pointerEvents: "none", // Ensure the div doesn't interfere with canvas interactions
            zIndex: 1000, // Ensure the div is above other page content
          }}
        >
          {tooltipContent.name}
          <br />
          Volume: {tooltipContent.volume}
        </div>
      )}
    </>
  );
};

export default CirclePackingZoomable;
