import * as React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function PanelHeading({ panelTitle, togglePanel, isPanelOpen }) {
  return (
    <Box
      width="100%"
      backgroundColor={grey[100]}
      display="flex"
      alignItems="center"
    >
      <ExpandMoreIcon
        onClick={() => {
          togglePanel(!isPanelOpen);
        }}
        fontSize="small"
        sx={{
          transform: isPanelOpen ? "rotate(0deg)" : "rotate(-90deg)",
          transition: "transform 0.25s",
        }}
      />
      <Typography variant="subtitle2" component="div" noWrap>
        {panelTitle}
      </Typography>
    </Box>
  );
}
