import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Chip from "@mui/material/Chip";
import SettingsIcon from "@mui/icons-material/Settings";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DatasetIcon from "@mui/icons-material/Dataset";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  color: "white",
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function DashboardDrawer({
  theme,
  open,
  handleDrawerClose,
  currentTab,
  setCurrentTab,
}) {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar variant="dense">
        <IconButton edge="start" onClick={handleDrawerClose} color="inherit">
          🌳
        </IconButton>
        <Typography variant="h6" noWrap sx={{ fontWeight: "bold" }}>
          Acadia
        </Typography>
        <Chip
          label="Beta"
          sx={{
            ml: 1,
            borderRadius: "4px",
            height: "20px",
          }}
        />

        <IconButton
          onClick={handleDrawerClose}
          sx={{ display: "block", ml: "auto" }}
          edge="end"
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      {/* <Divider /> */}
      <List>
        <ListItem key={"settings"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Project Settings"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {/* </List> */}
        {/* <Divider /> */}
        {/* <List> */}
        <ListItem
          key={"dataset_analysis"}
          disablePadding
          sx={{ display: "block" }}
        >
          <ListItemButton
            selected={currentTab === "dataset_analysis"}
            onClick={() => setCurrentTab("dataset_analysis")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ScatterPlotIcon />
            </ListItemIcon>
            <ListItemText primary={"Analysis"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"tag_jobs"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={currentTab === "tag_jobs"}
            onClick={() => setCurrentTab("tag_jobs")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ChecklistRtlIcon />
            </ListItemIcon>
            <ListItemText primary={"Tag Jobs"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"dataset"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <DatasetIcon />
            </ListItemIcon>
            <ListItemText primary={"Dataset"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"skill_tree"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Skill Tree"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
