import API from "../../api/API";

const getSkillValueVectors = async ({ projectId, leafSkill }) => {
  try {
    const data = await API.post(
      `/legacy_demo/get_skill_value_vectors?project-id=${projectId}`,
      {
        leaf_skill: leafSkill,
      }
    );

    const datum_vectors = data["datum_vectors"];

    return datum_vectors;
  } catch (error) {
    console.error("Could not get datum_vectors:", error);
    throw error;
  }
};

export default getSkillValueVectors;
