import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { deleteProject } from "../../api/ProjectsApi";

const ProjectMenu = ({ projectId, refreshProjects, onEdit = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      await deleteProject(projectId);
      setLoading(false);
      handleDialogClose(); // Close dialog on successful deletion
      // Optionally, trigger any parent component refresh or navigation here
      refreshProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
      setLoading(false);
      setError("Failed to delete project. Please try again.");
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="project-menu"
        aria-haspopup="true"
        onClick={(event) => {
          event.stopPropagation();
          handleClick(event);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="project-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onEdit(projectId);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: "error.main" }}>
          Delete
        </MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <DialogContentText id="alert-dialog-description">
              {error
                ? error
                : "Are you sure you want to delete this project? This action cannot be undone."}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            disabled={loading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectMenu;
