import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

// Right now allTags is kind of useless, but if we upgade
// The representation of allTags to encapsualte parent children relationships,
// This will help filter the multiselect
export default function SearchTags({
  allTags,
  tagColorMap,
  selectedFilters,
  setSelectedFilters,
}) {
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    const structuredArray = Object.entries(tagColorMap).map(
      ([tagName, color]) => ({
        title: tagName,
        color: color,
      })
    );

    setTagOptions(structuredArray);
  }, [tagColorMap]);

  return (
    <Box>
      {/* Hack to get the Autocomplete to rerender */}
      {selectedFilters && (
        <Autocomplete
          multiple
          id="size-small-outlined-multi"
          size="small"
          limitTags={2}
          options={tagOptions.map((tagOption) => tagOption.title)}
          filterSelectedOptions
          // Set the value to the selectedFilters from props
          value={selectedFilters} // Control the component with the value prop
          onChange={(event, newValue) => {
            setSelectedFilters(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const color = tagColorMap[option] || "grey"; // Fallback color if not found
              return (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  size="small"
                  style={{ backgroundColor: color }}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              // variant="filled"
              label="Filter"
              sx={{
                fontSize: "small",
                backgroundColor: "white",
                borderRadius: 2,
              }}
            />
          )}
        />
      )}
    </Box>
  );
}
