import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import { tagDataset } from "../../api/DatasetApi";

const ProjectAction = ({ projectRow, refreshProjects, onProjectClick }) => {
  // Determine if the project has a dataset loaded
  const [initializedTagging, setInitializedTagging] = useState(false); // State to handle loading

  const handleTagDatasetClick = async () => {
    setInitializedTagging(true); // Start loading
    try {
      await tagDataset(projectRow.id);
      refreshProjects();
    } catch (error) {
      console.error("Error tagging dataset:", error);
    }
    setInitializedTagging(false); // End loading
  };

  if (projectRow.datasetSize) {
    return (
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          onProjectClick(projectRow.id);
        }}
      >
        View Dashboard
      </Button>
    );
  } else if (projectRow.datasetPath && !projectRow.datasetSize) {
    return (
      <Box display="flex">
        {initializedTagging ? (
          <CircularProgress size="20px" />
        ) : (
          <Button
            variant="contained"
            size="small"
            onClick={handleTagDatasetClick}
          >
            Tag Dataset
          </Button>
        )}
      </Box>
    );
  } else {
    return <Typography color="error">An error has occured</Typography>;
  }
};

export default ProjectAction;
