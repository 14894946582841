import API from "../../api/API";

const getTagJobs = async (projectId) => {
  try {
    // Assuming the response is in JSON format
    const data = await API.post(
      `/tag_job/get_jobs?project-id=${projectId}`,
      {}
    );

    const tagJobs = data["tag_jobs"];
    return tagJobs;
  } catch (error) {
    console.error("Could not fetch tag jobs:", error);
    // Optional: Return mockTags or an error message here if the API call fails
    // return mockTags;
    // Or simply re-throw the error to be handled by the caller
    throw error;
  }
};

export default getTagJobs;
