import React, { useState, useEffect, useRef } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Box from "@mui/material/Box";
import PanelHeading from "./PanelHeading";
import PanelGroupHeading from "./PanelGroupHeading";
import getVectors from "../../functions/dataset_analysis_demo_functions/getVectors";

import SearchTags from "./SearchTags";
import DataGrid from "./dataset_analysis_demo_components/DatapointExplorerCard/DataGrid";
import SkillGraphCard from "./dataset_analysis_demo_components/SkillTaxonomyExplorerCard/SkillGraphCard";
import EmbeddingViewCard from "./dataset_analysis_demo_components/DatapointExplorerCard/EmbeddingViewCard";
import TagInformation from "./dataset_analysis_demo_components/SkillTaxonomyExplorerCard/TagInformation";
import TreeView from "./dataset_analysis_demo_components/SkillTaxonomyExplorerCard/TreeView";

import getDefaultCircleGraph from "../../functions/dataset_analysis_demo_functions/getDefaultCircleGraph";
import getFailureCaseCircleGraph from "../../functions/dataset_analysis_demo_functions/getFailureCaseCircleGraph";

import getAllTags from "../../functions/dataset_analysis_demo_functions/getAllTags";
import getDatapoints from "../../functions/dataset_analysis_demo_functions/getDatapoints";
import getTagColorMap from "../../functions/dataset_analysis_demo_functions/getTagColorMap";
import DatapointInformation from "./dataset_analysis_demo_components/DatapointExplorerCard/DatapointInformation";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function DatasetAnalysisTab({
  projectId,
  theme,
  failureMode = false,
}) {
  const [allTags, setAllTags] = useState([]);
  const [tagColorMap, setTagColorMap] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [circleGraph, setCircleGraph] = useState({});
  const [selectedDatapointID, setSelectedDatapointID] = useState(null);
  const [hoveredTag, setHoveredTag] = useState(null);
  const [hoveredDatapoint, setHoveredDatapoint] = useState(null);
  const [strictMatch, setStrictMatch] = useState(true);
  const [dimensions, setDimensions] = useState(2);
  const [useDynamicReduction, setUseDynamicReduction] = useState(false);
  const [datumVectors, setDatumVectors] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);

  const [skillGraphOpen, setSkillGraphOpen] = useState(true);
  const [skillTreeOpen, setSkillTreeOpen] = useState(true);
  const [skillMetadataOpen, setSkillMetadataOpen] = useState(true);
  const [datasetOpen, setDatasetOpen] = useState(true);
  const [metadataOpen, setMetadataOpen] = useState(true);

  const skillGraphRef = useRef(null);
  const skillTreeRef = useRef(null);
  const skillMetadataRef = useRef(null);
  const datasetRef = useRef(null);
  const metadataRef = useRef(null);

  const [error, setError] = useState(null); // Add an error state

  useEffect(() => {
    if (!skillGraphOpen) {
      skillGraphRef.current.collapse();
    } else {
      skillGraphRef.current.expand();
    }
  }, [skillGraphOpen]);

  useEffect(() => {
    console.log(skillTreeOpen);
    if (!skillTreeOpen) {
      skillTreeRef.current.collapse();
    } else {
      skillTreeRef.current.expand();
    }
  }, [skillTreeOpen]);

  useEffect(() => {
    if (!skillMetadataOpen) {
      skillMetadataRef.current.collapse();
    } else {
      skillMetadataRef.current.expand();
    }
  }, [skillMetadataOpen]);

  // Open when
  // It was closed before and a tag is selected
  useEffect(() => {
    if (!selectedTag && skillMetadataOpen) {
      setSkillMetadataOpen(false);
    }
  }, [selectedTag]);

  useEffect(() => {
    if (!datasetOpen) {
      datasetRef.current.collapse();
    } else {
      datasetRef.current.expand();
    }
  }, [datasetOpen]);

  useEffect(() => {
    if (!metadataOpen) {
      metadataRef.current.collapse();
    } else {
      metadataRef.current.expand();
    }
  }, [metadataOpen]);

  // useEffect(() => {
  //   if (!selectedDatapointID && metadataOpen) {
  //     setMetadataOpen(false);
  //   }
  // }, [selectedDatapointID]);

  useEffect(() => {
    const fetchDefaultCircleGraph = async () => {
      await getDefaultCircleGraph({ projectId })
        .then((graph) => {
          setCircleGraph(graph);
        })
        .catch((error) => {
          setError(error.toString()); // Set the error state on failure
        });
    };

    const fetchFailureCircleGraph = async () => {
      await getFailureCaseCircleGraph()
        .then((graph) => {
          setCircleGraph(graph);
        })
        .catch((error) => {
          setError(error.toString()); // Set the error state on failure
        });
    };

    if (failureMode) {
      fetchFailureCircleGraph();
    } else {
      fetchDefaultCircleGraph();
    }
  }, [failureMode]);

  useEffect(() => {
    const fetchAllTags = async () => {
      const tags = await getAllTags(projectId);
      setAllTags(tags);
      const colorMap = getTagColorMap(tags);
      setTagColorMap(colorMap);
    };
    fetchAllTags();
  }, []);

  // useEffect(() => {
  //   const fetchDefaultData = async () => {
  //     const data = await getDatapoints({
  //       filters: selectedFilters,
  //       projectId: projectId,
  //     });
  //     setSelectedData(data);
  //     setSelectedDatapoint(null);
  //   };
  //   fetchDefaultData();
  // }, [selectedFilters]);

  useEffect(() => {
    setDatumVectors(null);
    const fetchVectors = async () => {
      await getVectors({
        projectId: projectId,
        filters: selectedFilters,
        strictMatch: strictMatch,
        dimensions: dimensions,
        useDynamicReduction: useDynamicReduction,
      })
        .then((vectors) => {
          setDatumVectors(vectors.datum_vectors);

          setSelectedData(vectors.datum_vectors);
          setSelectedDatapointID(null);
        })
        .catch((error) => {
          setError(error.toString()); // Set the error state on failure
        });
    };

    fetchVectors();
  }, [selectedFilters, strictMatch, dimensions, useDynamicReduction]);

  return (
    <Box height="100%" width="100%">
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error" color="warning">
          {error}
        </Alert>
      </Snackbar>

      <PanelGroup autoSaveId="example0" direction="horizontal">
        <Panel defaultSizePercentage={25} minSize={10}>
          <PanelGroupHeading panelTitle="Explore Topics" />

          <Box width="100%" height="100%">
            <PanelGroup autoSaveId="example1" direction="vertical">
              {selectedTag && (
                <PanelHeading
                  panelTitle="Selected Topic"
                  isPanelOpen={skillMetadataOpen}
                  togglePanel={setSkillMetadataOpen}
                />
              )}

              <Panel
                minSize={10}
                id="skill-metadata"
                collapsedSize={0}
                collapsible={true}
                defaultSize={10}
                ref={skillMetadataRef}
                onExpand={() => setSkillMetadataOpen(true)}
              >
                <TagInformation
                  projectId={projectId}
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  tagColorMap={tagColorMap}
                  selectedDatapointID={selectedDatapointID}
                  setSelectedDatapointID={setSelectedDatapointID}
                  hoveredTag={hoveredTag}
                  hoveredDatapoint={hoveredDatapoint}
                  setHoveredDatapoint={setHoveredDatapoint}
                  failureMode={failureMode}
                  selectedModels={selectedModels}
                  setError={setError}
                ></TagInformation>
              </Panel>
              <PanelResizeHandle children={<Box />} />

              <PanelHeading
                panelTitle="Topic Graph"
                isPanelOpen={skillGraphOpen}
                togglePanel={setSkillGraphOpen}
              />

              <Panel
                minSize={20}
                id="skill-graph"
                collapsedSize={0}
                collapsible={true}
                defaultSize={20}
                ref={skillGraphRef}
                onExpand={() => setSkillGraphOpen(true)}
              >
                <SkillGraphCard
                  projectId={projectId}
                  theme={theme}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  tagColorMap={tagColorMap}
                  circleGraph={circleGraph}
                  hoveredTag={hoveredTag}
                  setHoveredTag={setHoveredTag}
                  hoveredDatapoint={hoveredDatapoint}
                  failureMode={failureMode}
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                />
              </Panel>
              <PanelResizeHandle />

              <PanelHeading
                panelTitle="Topic Tree"
                isPanelOpen={skillTreeOpen}
                togglePanel={setSkillTreeOpen}
              />

              <>
                <Panel
                  minSize={10}
                  id="skill-tree"
                  collapsedSize={0}
                  collapsible={true}
                  defaultSize={15}
                  onExpand={() => setSkillTreeOpen(true)}
                  ref={skillTreeRef}
                >
                  <TreeView
                    data={circleGraph}
                    selectedFilters={selectedFilters}
                    tagColorMap={tagColorMap}
                    hoveredTag={hoveredTag}
                    setHoveredTag={setHoveredTag}
                    hoveredDatapoint={hoveredDatapoint}
                    failureMode={failureMode}
                    selectedTag={selectedTag}
                    setSelectedTag={setSelectedTag}
                  />
                </Panel>
                <PanelResizeHandle />
              </>

              <Panel></Panel>
            </PanelGroup>
          </Box>
        </Panel>
        <PanelResizeHandle style={{ width: "2px", background: "lightgrey" }} />
        <Panel minSize={10} maxSize={100}>
          <EmbeddingViewCard
            datapoints={selectedData}
            tagColorMap={tagColorMap}
            selectedDatapointID={selectedDatapointID}
            setSelectedDatapointID={setSelectedDatapointID}
            hoveredTag={hoveredTag}
            hoveredDatapoint={hoveredDatapoint}
            setHoveredDatapoint={setHoveredDatapoint}
            failureMode={failureMode}
            projectId={projectId}
            setDimensions={setDimensions}
            dimensions={dimensions}
            setUseDynamicReduction={setUseDynamicReduction}
            useDynamicReduction={useDynamicReduction}
            setDatumVectors={setDatumVectors}
            datumVectors={datumVectors}
            allTags={allTags}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            selectedModels={selectedModels}
            setSelectedModels={setSelectedModels}
          />
        </Panel>
        <PanelResizeHandle style={{ width: "2px", background: "lightgrey" }} />
        <Panel defaultSizePercentage={25} minSize={10}>
          <PanelGroupHeading panelTitle="Inspect Data" />
          <Box width="100%" height="90vh">
            <PanelGroup autoSaveId="example2" direction="vertical">
              {selectedDatapointID && (
                <PanelHeading
                  panelTitle="Selected Datapoint"
                  isPanelOpen={metadataOpen}
                  togglePanel={setMetadataOpen}
                />
              )}

              <>
                <Panel
                  minSize={10}
                  id="metadata"
                  collapsedSize={0}
                  collapsible={true}
                  defaultSize={50}
                  ref={metadataRef}
                  onExpand={() => setMetadataOpen(true)}
                >
                  {selectedDatapointID && (
                    <DatapointInformation
                      projectId={projectId}
                      selectedDatapointID={selectedDatapointID}
                      tagColorMap={tagColorMap}
                      circleGraph={circleGraph}
                    />
                  )}
                </Panel>
                <PanelResizeHandle />
              </>
              <PanelHeading
                panelTitle="Dataset"
                isPanelOpen={datasetOpen}
                togglePanel={setDatasetOpen}
              />

              <>
                <Panel
                  minSize={20}
                  id="dataset"
                  collapsedSize={0}
                  collapsible={true}
                  defaultSize={50}
                  ref={datasetRef}
                  onExpand={() => setDatasetOpen(true)}
                >
                  <DataGrid
                    datapoints={selectedData}
                    selectedDatapointID={selectedDatapointID}
                    setSelectedDatapointID={setSelectedDatapointID}
                    columns={4}
                  />
                </Panel>
                <PanelResizeHandle />
              </>

              <Panel></Panel>
            </PanelGroup>
          </Box>
        </Panel>
      </PanelGroup>
    </Box>
  );
}
