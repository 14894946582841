import API from "../../api/API";

const getDefaultCircleGraph = async ({projectId}) => {
  try {
    const data = await API.post(`/legacy_demo/get_default_circle_graph?project-id=${projectId}`, {});

    const default_circle_graph = data["default_circle_graph"];

    return default_circle_graph;
  } catch (error) {
    console.error("Could not get default circle graph:", error);
    throw error;
  }
};

export default getDefaultCircleGraph;
