import API from "./API";

export const createProject = async (name, description) => {
  const payload = { name, description };
  try {
    const data = await API.post("/project/create", payload); // Ensure the endpoint matches your API Gateway configuration
    return data["projectId"];
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

export const updateProjectS3Details = async (
  projectId,
  s3BucketName,
  roleArn,
  datasetPath,
  metadataPath,
  datasetDescription,
  taskDescription
) => {
  try {
    const s3Details = {
      s3BucketName: s3BucketName,
      roleArn: roleArn,
      datasetPath: datasetPath,
      metadataPath: metadataPath,
      datasetDescription: datasetDescription,
      taskDescription: taskDescription,
    };

    const data = await API.post(
      `/project/update_s3_details?project-id=${projectId}`,
      s3Details
    );

    return data; // Assuming the response data structure matches that of the lambda
  } catch (error) {
    console.error("Error updating project S3 details:", error);
    throw error;
  }
};

export const readProject = async (projectId) => {
  try {
    // The project-id is passed as a query parameter here
    const data = await API.post(`/project/read?project-id=${projectId}`, {});
    return data; // The lambda seems to return a JSON string, so parse it
  } catch (error) {
    console.error("Error reading project:", error);
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  try {
    // The project-id is passed as a query parameter here
    const data = await API.post(`/project/delete?project-id=${projectId}`, {});
    return data; // The lambda seems to return a JSON string, so parse it
  } catch (error) {
    console.error("Error deleting project:", error);
    throw error;
  }
};
