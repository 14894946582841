import React, { useState, useEffect } from "react";
import { Card, Typography, Chip, Box, Button } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
import getSkillValueVectors from "../../../../functions/dataset_analysis_demo_functions/getSkillValueVectors";
import SkillValueEmbedding2DView from "./SkillValueEmbedding2DView";
import { grey } from "@mui/material/colors";
import { Paper } from "@mui/material";
import { Stack } from "@mui/material";
import { Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const TagInformation = ({
  projectId,
  selectedTag,
  setSelectedTag,
  selectedFilters,
  setSelectedFilters,
  tagColorMap,
  selectedDatapointID,
  setSelectedDatapointID,
  hoveredTag,
  hoveredDatapoint,
  setHoveredDatapoint,
  failureMode,
  selectedModels,
  setError,
}) => {
  const [leafSkillValueVectors, setLeafSkillValueVectors] = useState(null);

  useEffect(() => {
    setLeafSkillValueVectors(null);
  }, [selectedTag]);

  useEffect(() => {
    if (
      selectedTag &&
      selectedTag.children &&
      selectedTag.children.length == 0
    ) {
      getSkillValueVectors({
        projectId: projectId,
        leafSkill: selectedTag.name,
      })
        .then((res) => {
          setLeafSkillValueVectors(res);
        })
        .catch((error) => {
          setError(error.toString());
        });
    } else {
      setLeafSkillValueVectors(null);
    }
  }, [selectedTag]);

  if (!selectedTag) {
    // Optionally, return null or a placeholder component
    return null;
  }

  const updateFilters = () => {
    // Check if the tag is already included in the selected filters
    if (selectedFilters.includes(selectedTag.name)) {
      // It's already included, so we remove it
      setSelectedFilters(selectedFilters.filter((t) => t !== selectedTag.name));
    } else {
      // It's not included, so we add it
      setSelectedFilters([...selectedFilters, selectedTag.name]);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%", padding: 2, overflow: "auto" }}>
      <Stack
        spacing={1}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <Typography
            variant="body"
            sx={{ fontWeight: "bold" }}
            component="div"
          >
            {selectedTag.name}
          </Typography>
          <Button variant="outlined" onClick={updateFilters} size="small">
            {selectedFilters.includes(selectedTag.name)
              ? "Remove Filter"
              : "Add Filter"}
          </Button>
        </Box>

        {selectedTag &&
          selectedTag.children &&
          selectedTag.children.length === 0 && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  // justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "bold" }}
                  component="div"
                >
                  Value embeddings
                </Typography>
                <Box
                  // make this always a box of width 100%
                  sx={{
                    width: "100%",
                    height: "0",
                    paddingTop: "100%",
                    position: "relative",
                    outline: `1px solid lightgrey `,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      // Your content here
                    }}
                  >
                    {leafSkillValueVectors ? (
                      <SkillValueEmbedding2DView
                        datapoints={leafSkillValueVectors}
                        tagColorMap={tagColorMap}
                        selectedDatapointID={selectedDatapointID}
                        setSelectedDatapointID={setSelectedDatapointID}
                        hoveredTag={hoveredTag}
                        hoveredDatapoint={hoveredDatapoint}
                        setHoveredDatapoint={setHoveredDatapoint}
                        failureMode={failureMode}
                        selectedModels={selectedModels}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="caption"
            sx={{ fontWeight: "bold" }}
            component="div"
          >
            Volume
          </Typography>
          <Typography variant="caption" component="div">
            {selectedTag.volume}
          </Typography>
        </Box>

        {/* You can add more rows of metadata here following the same pattern */}
      </Stack>
    </Box>
  );
};

export default TagInformation;
