import React from "react";
import { TextField, Typography, Button, Box } from "@mui/material";

const Step1_ProjectDetails = ({ projectDetails, onInputChange }) => {
  return (
    <Box
      className="step1-project-details"
      maxWidth="sm"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Typography variant="h4" gutterBottom>
        Create your project
      </Typography>
      <TextField
        variant="outlined"
        name="name"
        label="Enter Project Name"
        value={projectDetails.name}
        onChange={onInputChange}
        fullWidth
      />
      <TextField
        variant="outlined"
        name="description"
        label="Description (Optional)"
        value={projectDetails.description}
        onChange={onInputChange}
        multiline
        rows={4}
        fullWidth
      />
    </Box>
  );
};

export default Step1_ProjectDetails;
