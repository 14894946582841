import React from "react";
import { Typography, Box } from "@mui/material";

const Step3_PrepareS3Bucket = ({ onNext, onPrev }) => {
  return (
    <Box className="step3-prepare-s3-bucket" maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        Prepare your Amazon S3 Bucket
      </Typography>

      <Box sx={{ my: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Download and modify the CloudFormation Template
        </Typography>
        <Typography variant="body2">Further instructions</Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Access the AWS CloudFormation Console
        </Typography>
        <Typography variant="body2">Further instructions</Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Launch the CloudFormation Stack
        </Typography>
        <Typography variant="body2">Further instructions</Typography>
      </Box>

      {/* Add buttons or any additional components as needed */}
    </Box>
  );
};

export default Step3_PrepareS3Bucket;
