import { createProject } from "../../api/ProjectsApi";
import { initializeSkills } from "../../api/SkillsApi";
import { updateProjectS3Details } from "../../api/ProjectsApi";

const setupProjectSkillsDataset = async ({
  name,
  description,
  skills,
  s3BucketName,
  iamRoleArn,
  datasetPath,
  metadataPath,
  datasetDescription,
  taskDescription,
}) => {
  try {
    const projectId = await createProject(name, description);
    await initializeSkills(projectId, skills);
    await updateProjectS3Details(
      projectId,
      s3BucketName,
      iamRoleArn,
      datasetPath,
      metadataPath,
      datasetDescription,
      taskDescription
    );
  } catch (error) {
    return error;
  }
};

export default setupProjectSkillsDataset;
