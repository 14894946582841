import React, { useState, useEffect } from "react";
import Embedding3DView from "./Embedding3DView";
import Embedding2DView from "./Embedding2DView";
import {
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  FormControlLabel,
  Typography,
  Box,
  CircularProgress,
  Popover,
} from "@mui/material";

import SearchTags from "../../SearchTags";
import ModelSelect from "./ModelSelect";
import { grey } from "@mui/material/colors";

const EmbeddingViewCard = ({
  datapoints,
  tagColorMap,
  hoveredTag,
  hoveredDatapoint,
  setHoveredDatapoint,
  failureMode,
  selectedDatapointID,
  setSelectedDatapointID,
  setDimensions,
  dimensions,
  setUseDynamicReduction,
  useDynamicReduction,
  setDatumVectors,
  datumVectors,
  allTags,
  selectedFilters,
  setSelectedFilters,
  selectedModels,
  setSelectedModels,
}) => {
  const [data, setData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setTooltipPosition({ x: event.pageX, y: event.pageY });
    };

    // Add event listener to the window or specific SVG ref
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    setData(datumVectors);
  }, [datapoints]);

  const handleViewChange = (event, newDimensions) => {
    if (newDimensions !== null) {
      setDatumVectors(null);
      setDimensions(newDimensions);
    }
  };

  const handleModeChange = (event) => {
    setUseDynamicReduction(event.target.checked);
  };

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100vh" }}>
      {/* Top Panel */}
      <Box
        backgroundColor={grey[100]}
        sx={{
          position: "absolute",
          top: 0, // Adjust spacing as necessary
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          zIndex: 10, // Ensures the control panel is above the embedding view
          padding: "10px",
          borderBottom: "2px solid lightgrey",
        }}
      >
        <Box>
          <SearchTags
            allTags={allTags}
            tagColorMap={tagColorMap}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <ModelSelect
            selectedModels={selectedModels}
            setSelectedModels={setSelectedModels}
          />
        </Box>
      </Box>
      {/* Embedding View */}
      {datumVectors ? (
        <>
          {dimensions === 3 ? (
            <Embedding3DView
              datapoints={datumVectors}
              tagColorMap={tagColorMap}
              selectedDatapointID={selectedDatapointID}
              setSelectedDatapointID={setSelectedDatapointID}
              hoveredTag={hoveredTag}
              hoveredDatapoint={hoveredDatapoint}
              setHoveredDatapoint={setHoveredDatapoint}
              failureMode={failureMode}
            />
          ) : (
            // Placeholder for Embedding2DView
            <Embedding2DView
              datapoints={datumVectors}
              tagColorMap={tagColorMap}
              selectedDatapointID={selectedDatapointID}
              setSelectedDatapointID={setSelectedDatapointID}
              hoveredTag={hoveredTag}
              hoveredDatapoint={hoveredDatapoint}
              setHoveredDatapoint={setHoveredDatapoint}
              failureMode={failureMode}
              selectedModels={selectedModels}
            />
          )}
        </>
      ) : (
        // Center the loading spinner
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* Bottom Panel */}
      <Box
        sx={{
          position: "absolute",
          bottom: 50, // Adjust spacing as necessary
          // right: 16, // Align to the left instead of centering
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          zIndex: 10, // Ensures the control panel is above the embedding view
          margin: "10px",
        }}
      >
        <Box>
          <ToggleButtonGroup
            value={dimensions}
            exclusive
            onChange={handleViewChange}
            aria-label="Dimension"
            size="small" // Adjust the size as necessary
          >
            <ToggleButton value={2} aria-label="2D View">
              2D
            </ToggleButton>
            <ToggleButton value={3} aria-label="3D View">
              3D
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <Typography variant="body2">
            <em>Dynamically reduce dimensions based on filters</em>
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={useDynamicReduction}
                onChange={handleModeChange}
                size="small"
              />
            }
            sx={{ ml: 2 }} // Adds some spacing between the toggle group and the switch
          />
        </Box>
      </Box>
      {hoveredDatapoint && (
        <>
          <div
            style={{
              position: "fixed",
              left: tooltipPosition.x + "px", // Adjusted for proper positioning
              top: tooltipPosition.y + "px", // Adjusted for proper positioning
              transform: "translate(10px, 10px)", // Adjust for cursor centering
              pointerEvents: "none", // Ensure the div doesn't interfere with canvas interactions
              zIndex: 100, // Ensure the div is above other page content
            }}
          >
            {hoveredDatapoint.skill_value_description ? (
              <Box
                style={{
                  maxWidth: "150px",
                  height: "auto",
                  // border: "2px solid black",
                  borderRadius: "5px",
                  backgroundColor: "#3b3b3b",
                  color: "white",
                  padding: "5px",
                  fontSize: "10px",
                }}
              >
                <div>
                  <img
                    src={hoveredDatapoint.preview_images[0]}
                    alt="Data Point Preview"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <Typography varient="caption">
                  {/* strip of any " or : */}
                  {hoveredDatapoint.skill_value_description.replace(
                    /["':]/g,
                    ""
                  )}
                </Typography>
              </Box>
            ) : hoveredDatapoint.preview_images[0] ? (
              <div>
                <img
                  src={hoveredDatapoint.preview_images[0]}
                  alt="Data Point Preview"
                  style={{
                    maxWidth: "150px",
                    height: "auto",
                    border: "2px solid black",
                    borderRadius: "5px",
                  }}
                />
              </div>
            ) : (
              <>
                {" "}
                <Box
                  style={{
                    maxWidth: "150px",
                    height: "auto",
                    // border: "2px solid black",
                    borderRadius: "5px",
                    backgroundColor: "#3b3b3b",
                    color: "white",
                    padding: "5px",
                    fontSize: "7px",
                  }}
                >
                  <Typography varient="caption">
                    {hoveredDatapoint.data_row["caption_0"]}
                  </Typography>
                </Box>
              </>
            )}
          </div>
        </>
      )}
    </Box>
  );
};

export default EmbeddingViewCard;
