import API from "../../api/API";

// TODO: Refactor to use get skills endpoint

// Updated getAllTags function to fetch tags from the backend API
const getAllTags = async (projectId) => {
  try {
    // Assuming the response is in JSON format
    const data = await API.post(
      `/skill/get_skills?project-id=${projectId}`,
      {}
    );

    const skill_objects = data["skills"];
    const skills = skill_objects.map((skill) => skill["name"]);

    return skills;
  } catch (error) {
    console.error("Could not fetch tags:", error);
    // Optional: Return mockTags or an error message here if the API call fails
    // return mockTags;
    // Or simply re-throw the error to be handled by the caller
    throw error;
  }
};

export default getAllTags;
