import React, { useState, useEffect } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* Quick Filter */}
      <Tooltip title="Search">
        <div>
          <GridToolbarQuickFilter />
        </div>
      </Tooltip>
      {/* Export Button */}
      {/* <Tooltip title="Export">
        <IconButton>
          <GridToolbarExport />
        </IconButton>
      </Tooltip> */}
      {/* Add more toolbar actions here with similar pattern */}
    </GridToolbarContainer>
  );
}

const CustomDataGrid = ({
  datapoints,
  selectedDatapointID,
  setSelectedDatapointID,
}) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (datapoints) {
      // Convert datapoints to DataGrid compatible format
      const formattedRows = Object.values(datapoints).map((datapoint) => {
        let row = { acadia_id: datapoint.id, img: datapoint.preview_images[0] };
        // loop through datapoint.data_row and add to row
        for (const [key, value] of Object.entries(datapoint.data_row)) {
          // if the key is of the form image_<i>, then index into datapoint.preview_images
          if (key.startsWith("image_")) {
            const index = parseInt(key.split("_")[1]);
            row[key] = datapoint.preview_images[index];
          } else {
            row[key] = value;
          }
        }

        return row;
      });
      setRows(formattedRows);
    }
  }, [datapoints]);

  // Define columns for DataGrid
  // find the keys of the first datapoint.data_row and use them as columns
  let data_row_keys = [];
  try {
    data_row_keys = Object.keys(
      datapoints[Object.keys(datapoints)[0]].data_row
    );
  } catch (error) {
    data_row_keys = [];
    console.log("No datapoints found");
  }
  // Construct the columns, first grabbing the id and img columns, then captions
  let columns = [{ field: "id", headerName: "ID", flex: 0.5 }];

  // Add the images
  for (let i = 0; i < data_row_keys.length; i++) {
    if (data_row_keys[i].startsWith("image_")) {
      columns.push({
        field: data_row_keys[i],
        headerName: data_row_keys[i],
        flex: 1,
        renderCell: (params) => (
          <img
            src={params.value}
            alt=""
            style={{ width: "auto", height: "100%" }}
          />
        ),
      });
    }
  }

  // Add the captions
  for (let i = 0; i < data_row_keys.length; i++) {
    if (data_row_keys[i].startsWith("caption_")) {
      columns.push({
        field: data_row_keys[i],
        headerName: data_row_keys[i],
        flex: 1,
      });
    }
  }

  // const columns = [
  //   {
  //     field: "img",
  //     headerName: "Image",
  //     flex: 1,
  //     renderCell: (params) => (
  //       <img
  //         src={params.value}
  //         alt=""
  //         style={{ width: "50%", height: "auto" }}
  //       />
  //     ),
  //   },
  //   {
  //     field: "id",
  //     headerName: "ID",
  //     flex: 1,
  //   },
  // ];

  const handleRowClick = (param) => {
    const id = param.row.acadia_id;
    if (selectedDatapointID && id === selectedDatapointID) {
      setSelectedDatapointID(null);
    } else {
      setSelectedDatapointID(id);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        flexGrow: 1,
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      <Box style={{ height: "100%", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          // disableColumnFilter
          // disableColumnSelector
          // disableDensitySelector
          density="compact"
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={5}
          onRowClick={handleRowClick}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default CustomDataGrid;
