import API from "./API";

export const tagDataset = async (projectId) => {
  try {
    // The project-id is passed as a query parameter here
    const data = await API.post(
      `/dataset/populate?project-id=${projectId}`,
      {}
    );
    return data;
  } catch (error) {
    console.error("Error populating dataset:", error);
    throw error;
  }
};
