import API from "../../api/API";

const retryTagJobs = async (projectId) => {
  try {
    // Assuming the response is in JSON format
    const data = await API.post(
      `/tag_job/retry_jobs?project-id=${projectId}`,
      {}
    );

    const retriedSQSMessageIds = data["retry_sqs_message_ids"];
    return retriedSQSMessageIds;
  } catch (error) {
    console.error("Could not retry:", error);
    // Optional: Return mockTags or an error message here if the API call fails
    // return mockTags;
    // Or simply re-throw the error to be handled by the caller
    throw error;
  }
};

export default retryTagJobs;
