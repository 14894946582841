/*
 * src/pages/ProjectManagerPage.js displays a list of projects fetched from the server.
 * It allows users to initiate the creation of a new project and provides
 * actions for each project via an interactive menu.
 *
 * The component handles the loading state when projects are being fetched,
 * an error state for any issues during the fetch process, and a message
 * when no projects are present. It uses Redux to manage state and dispatch actions.
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavBar from "../components/common_components/NavBar"; // Adjust the import path if necessary
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Collapse from "@mui/material/Collapse";
import ProjectList from "../components/project_management_components/ProjectList"; // Adjust the import path if necessary

import { getUserProjects } from "../api/UserApi"; // Adjust the import path if necessary

const ProjectManagerPage = ({ theme, signOut, user }) => {
  const [projects, setProjects] = useState([]);
  const [projectsAreLoading, setProjectsAreLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProjectsVisible, setIsProjectsVisible] = useState(true);
  const [polling, setPolling] = useState(false); // New state for polling

  const navigate = useNavigate();
  const intervalTime = 5000; // Adjust the interval as needed

  const refreshProjects = async () => {
    setError(null);

    try {
      const data = await getUserProjects();
      setProjects(data);

      // Check if any projects have a non-zero number_of_pending_datums_to_tag
      const anyPending = data.some(
        (project) => project.number_of_pending_datums_to_tag > 0
      );

      if (anyPending && !polling) {
        setPolling(true);
      } else if (!anyPending && polling) {
        setPolling(false);
      }
    } catch (error) {
      setError(error.message);
    }
    setProjectsAreLoading(false);
  };

  useEffect(() => {
    refreshProjects(); // Initial fetch

    let intervalId;
    if (polling) {
      // Start polling
      intervalId = setInterval(() => {
        refreshProjects();
      }, intervalTime); // Adjust the interval as needed
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [polling]);

  const handleCreateProjectClick = () => {
    navigate("/projects/new");
  };

  const handleProjectClick = (projectId) => {
    navigate(`/dashboard/${projectId}`);
  };

  const handleToggleProjects = () => {
    setIsProjectsVisible(!isProjectsVisible);
  };

  return (
    <div className="project-manager">
      <NavBar user={user} onSignOut={signOut} />
      <Box pt={theme.spacing(5)}>
        <Container maxWidth="lg" disableGutters={false}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Projects</Typography>

            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCreateProjectClick}
            >
              Create Project
            </Button>
          </Box>
          <Typography variant="subtitle1" color="textSecondary">
            Explore your projects or start a new one.
          </Typography>
          <Box pt={theme.spacing(3)}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <IconButton onClick={handleToggleProjects} size="large">
                <ExpandMoreIcon
                  sx={{
                    transform: isProjectsVisible
                      ? "rotate(0deg)"
                      : "rotate(-90deg)",
                    transition: "transform 0.25s",
                  }}
                />
              </IconButton>
              <Typography variant="h5">All Projects</Typography>
            </Box>
            <Collapse in={isProjectsVisible}>
              {projectsAreLoading ? ( //Center the circlular Progress
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : error ? (
                <p>Could not load projects: {error}</p>
              ) : projects.length > 0 ? (
                <>
                  <ProjectList
                    projects={projects}
                    onProjectClick={handleProjectClick}
                    refreshProjects={refreshProjects}
                  />
                </>
              ) : (
                <p>No projects created.</p>
              )}
            </Collapse>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ProjectManagerPage;
