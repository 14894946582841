function stringToColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Ensure colors are in the higher range for pastel by adding a base value and limiting the maximum
  const base = 127; // Base value for ensuring pastel colors (lighter colors)
  const range = 128; // Range above the base value

  const r = (hash >> 16) & 0xff;
  const g = (hash >> 8) & 0xff;
  const b = hash & 0xff;

  // Adjust colors to be pastel
  const pastelR = base + (r % range);
  const pastelG = base + (g % range);
  const pastelB = base + (b % range);

  // Convert the adjusted components to hexadecimal
  const red = ("0" + pastelR.toString(16)).slice(-2);
  const green = ("0" + pastelG.toString(16)).slice(-2);
  const blue = ("0" + pastelB.toString(16)).slice(-2);

  return `#${red}${green}${blue}`;
}

function mapTagsToPastelColors(tags) {
  const colorMap = {};
  tags.forEach((tag) => {
    colorMap[tag] = stringToColor(tag);
  });
  return colorMap;
}

const getTagColorMap = (tags) => {
  return mapTagsToPastelColors(tags);
};

export default getTagColorMap;
