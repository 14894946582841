import API from "../../api/API";

const getVectors = async ({
  projectId,
  filters = [],
  strictMatch = true,
  methodName = "tsne",
  dimensions,
  useDynamicReduction,
}) => {
  try {
    const data = await API.post(
      `/legacy_demo/get_vectors?project-id=${projectId}`,
      {
        filters: filters,
        strict: strictMatch,
        method_name: methodName,
        dimensions: dimensions,
        use_dynamic_reduction: useDynamicReduction,
        page_size: 1000,
      }
    );

    const datum_vectors = data["datum_vectors"];

    return { datum_vectors: datum_vectors };
  } catch (error) {
    console.error("Could not get datum_vectors:", error);
    throw error;
  }
};

export default getVectors;
